import { tw } from '@/utils/tw';
import BaseRowTheme from 'base/components/Row/theme';

const { Button } = BaseRowTheme;

const Row = tw.theme({
  extend: BaseRowTheme,
  slots: {
    base: `z-0`,
    headline: `text-headline-sm sm:text-headline-md`,
    caption: `text-content-xs sm:text-content-sm`,
    footer: `[&:not(:first-child)]:mt-4 sm:[&:not(:first-child)]:mt-6`,
  },
});

export default Object.assign(Row, { Button });
